import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import { Identifiable } from "@/models/general/Identifiable";
import { realoadTable } from '@/store/functions/GenericTable';
import { MutationsForm } from "@/store/commons/MutationsForm"
import { ActionsForm } from "@/store/commons/ActionsForm";
import NewStateState from "../../models/NewStateState";
import NewState from "@/models/state/NewState";
import StateCatalogService from "@/core/services/state/StateCatalogService";
import OptionSelect from "@/models/shared/OptionSelect";
import CountryComboService from "@/core/services/country/CountryComboService";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import StateInfo from "@/models/state/stateInfo";


@Module({dynamic: true, store, namespaced: true, name: Modules.NewStateModule})
class NewStateModule extends VuexModule implements NewStateState {
    selects = {
        optionsCountry: [] as OptionSelect[]
    };
    loadings =
    {
        countrySelect: false
    };
    idSelected = '';
    /**
     * Los valores iniciales son los valores del formulario, se usan para las ediciones
     */
    initialValues = formDefaultValues
    loading = false;
    

    get getValuesForm(): NewState {
        return this.initialValues;
    }
    /**
     * Indica si existe un identificador de un registro a modificar
     */
    get IsUpdateForm() : boolean {
        return this.idSelected != '';
    }
    
    @Mutation
    [MutationsForm.RESET_VALUES_FORM](){
        this.initialValues = formDefaultValues;
        this.selects.optionsCountry = [];
    }
    @Mutation
    [MutationsForm.RESET_ID_SELECT](){
        this.idSelected = '';
    }

    @Mutation
    [MutationsForm.SET_DATA_FORM](values: Identifiable<string, StateInfo>){
        if(values && (values.id ?? '') != ''){
            this.initialValues = values as NewState;
            this.idSelected = values.id;
            this.selects.optionsCountry = [{
                id: values.countryId ?? ''
                , name: values.country ?? ''
            }]
        }
    }

    @Mutation
    [MutationsForm.SET_LOADINGS](loadings: any) {
        
        if (typeof loadings == "boolean") {
            this.loading = loadings;
        } else { 
            SaveValuesToStore(this.loadings, loadings);
        }
    }

    @Action
    async [ActionsForm.SAVE_FORM](form: NewState){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.save(form)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.StateTableModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                } 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            })
    }
    @Action
    async [ActionsForm.UPDATE_FORM](newData: NewState){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.update(this.idSelected, newData)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.StateTableModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                } 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            });
    }
    @Action({ commit: MutationsForm.SET_DATA_FORM })
    async [ActionsForm.SEARCH_DATA_FORM](id: string){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return (await service.getInfo(id)
            .finally( () => this.context.commit(MutationsForm.SET_LOADINGS, false))).data ?? formDefaultValues
    }
    @MutationAction
    async getComboCountry(filterName: string) {
        this.context.commit(MutationsForm.SET_LOADINGS, { countrySelect: true });
        const countries = await serviceCountry.searchCombo(filterName)
            .finally(() => { 
                this.context.commit(MutationsForm.SET_LOADINGS, { countrySelect: false });
            });
        const selects = { ...this.selects }
        return { selects: { ...selects, optionsCountry: countries.data ?? [] }}
    }
}
const service =  new StateCatalogService();
const serviceCountry =  new CountryComboService();

/**
 * Valores por defecto del formulario
 */
 const formDefaultValues = {
    name: ''
    , countryId: ''
} as NewState

export default getModule(NewStateModule);


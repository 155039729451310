import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-10" }
const _hoisted_2 = { class: "form-label fw-bold" }
const _hoisted_3 = ["label"]
const _hoisted_4 = ["label", "value"]
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { class: "form-label fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDynamic = _resolveComponent("FilterDynamic")!

  return (_openBlock(), _createBlock(_component_FilterDynamic, {
    onClearFilters: _ctx.clean,
    onApply: _ctx.apply
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('STATUS')) + ":", 1),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select form-select-solid select2-hidden-accessible",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.status) = $event))
          }, [
            _createElementVNode("option", {
              label: _ctx.$t('ALL'),
              value: undefined
            }, null, 8, _hoisted_3),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionStatus, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option.id,
                label: option.name,
                value: option.id
              }, null, 8, _hoisted_4))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.status]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('FOLIO')) + ":", 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.folio) = $event)),
            placeholder: "folio del país",
            class: "form-control"
          }, null, 512), [
            [_vModelText, _ctx.folio]
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClearFilters", "onApply"]))
}
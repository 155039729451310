
import { defineComponent, toRefs, ref, computed, watch } from "vue";
import { Form, } from "vee-validate";
import InputText from "@/components/forms/InputText.vue" 
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue" 
import { FormTypes } from "@/core/enums/form-types"
import * as Validations from "yup";
import moduleForm from '@/store/modules/state/modules/new-state'
import NewState from "@/models/state/NewState";

export default defineComponent({
    components:{
        Form
        , InputText
        , SelectFilterRemote
    }
    , props:{
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    },
    emits: ['close-content']
    , setup(props, { emit }){
        const { formType } = toRefs(props);
        const form = ref();
        const validationSchema =  Validations.object().shape({
            name: Validations.string().default('').required()
            , countryId: Validations.string().default('').required()
        });
        
        const save = (form: NewState, { resetForm }) => {
             //call action
            if(formType.value == FormTypes.Create){
                moduleForm.SAVE_FORM(form).then( (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            } else {//edita si no es create
                moduleForm.UPDATE_FORM(form).then(  (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            }
        }
        const searchCountry = (filtername: string) => moduleForm.getComboCountry(filtername);

        const loading = computed(() => moduleForm.loading);
        const loadingCombo = computed(() => moduleForm.loadings.countrySelect);
        const countries = computed(() => moduleForm.selects.optionsCountry);

        watch(() => moduleForm.getValuesForm, (currentValue: NewState) => {
            if(currentValue && currentValue.name != ''){
                form.value.setValues({
                    ...(currentValue as NewState)
                });
            } else {
                form.value.resetForm();
            }

        })


        return {
            FormTypes
            , validationSchema
            , form
            , loading
            , countries
            , loadingCombo
            , save
            , searchCountry
        }
    }
})

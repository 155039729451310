
import { defineComponent, ref,  toRefs } from "vue";
import FilterDynamic from "@/components/filters/FiltersDynamic.vue"
import { translate } from "@/core/plugins/i18n";

export default defineComponent({
    components: {
        FilterDynamic
    },
    props:{
        filterStatus:{
            type: Boolean
            , default: undefined
        }
        ,filterFolio:{
            type: String
            , default: ''
        }
    },
    emits: ['update-filter'],
    setup(props, { emit }){
        const { filterStatus, filterFolio } = toRefs(props);
        const status = ref(filterStatus.value);
        const folio = ref(filterFolio.value);
        const optionStatus = ref([
            {
                id: true
                , name: translate("ENUMS.GENERIC_STATUS.ACTIVE")
            },
            {
                id: false
                , name: translate("ENUMS.GENERIC_STATUS.INACTIVE")
            },
        ]);

        const clean = () => {
            status.value = undefined;
            folio.value = '';
            apply();
        }
        const apply = () => {
            emit("update-filter", {status: status.value, folio: folio.value})
        }
        return {
            optionStatus
            , clean
            , apply
            , status
            , folio
        }
    }
});
